<template>
  <div class="flex flex-wrap" v-if="selectedApplications.length">
    <div v-for="id in selectedApplications" class="mr-4 p-1 rounded" :style="'background-color:'+applications[id].color">
      <img class="h-8" v-if="applications[id].image && applications[id].image.length" :src="applications[id].image" :alt="applications[id].name" :title="applications[id].name" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'pinia';
import { userStore } from '@ui/Stores/User.js';

export default {
  computed: {
    ...mapState(
        userStore, ['application', 'selectedApplications', 'applications'],
    ),
  },
}
</script>
