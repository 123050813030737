import EventBus from "@ui/eventBus.js";
import * as debugHelpers from './debug.js';
import StackTrace from "stacktrace-js";

/**
 * Return number of tries
 * @param error The AxiosError passed to the interceptor.
 */

function numberOfTries(error) {
    return (error.config.headers['X-Tries'] || 0);
}

/**
 * Retry current call
 * @param error The AxiosError passed to the interceptor.
 */
export function retry(error) {
    // check for config
    if (!error.config.retryConfig) {
        return false;
    }

    const retryConfig = error.config.retryConfig;
    // Create a promise that invokes the retry after the backOffDelay
    const onBackoffPromise = new Promise((resolve) => {
        // increment number of tries
        error.config.headers['X-Tries'] += 1;
        // set delay
        const delay = retryConfig.retryDelay;
        // backoff
        setTimeout(resolve, delay);
    });

    // Notify the user if they added an `onRetryAttempt` handler
    const onRetryAttemptPromise = retryConfig.onRetryAttempt
        ? Promise.resolve(retryConfig.onRetryAttempt(numberOfTries(error), error))
        : Promise.resolve();

    // Return the promise in which recalls axios to retry the request
    return Promise.resolve()
        .then(() => onBackoffPromise)
        .then(() => onRetryAttemptPromise)
        .then(() => window.axios.request(error.config));
}

/**
 * Retry current call
 * @param error The AxiosError passed to the interceptor.
 */
export function refreshCsrf(error) {
    // Refresh our session token
    window.axios.get('/sanctum/csrf-cookie').then((response) => {
        // Return the promise in which recalls axios to retry the request
        return Promise.resolve().then(() => window.axios.request(error.config));
    }).catch((error) => {
        console.log('whoeps', error);
    });
}

/**
 * Determine based on config if we should retry the request.
 * @param error The AxiosError passed to the interceptor.
 */
export function shouldRetryRequest(error) {
    // check for config
    if (!error.config.retryConfig) {
        return false;
    }

    const retryConfig = error.config.retryConfig;
    // If there's no config, or retries are disabled, return.
    if (retryConfig.retry === 0) {
        return false;
    }

    // Check if this error has no response (ETIMEDOUT, ENOTFOUND, etc)
    if (!error.response && numberOfTries(error) >= retryConfig.noResponseRetries) {
        return false;
    }

    // Only retry with configured HttpMethods.
    if (retryConfig.httpMethodsToRetry.indexOf(error.config.method.toUpperCase()) < 0) {
        return false;
    }

    // If this wasn't in the list of status codes where we want
    // to automatically retry, return.
    if (error.response && error.response.status) {
        let isInRange = false;
        for (const [min, max] of retryConfig.statusCodesToRetry) {
            const status = error.response.status;
            if (status >= min && status <= max) {
                isInRange = true;
                break;
            }
        }
        if (!isInRange) {
            return false;
        }
    }

    // If we are out of retry attempts, return
    if (numberOfTries(error) > retryConfig.retry) {
        if (error && error.stack) {
            const data = {
                type: 'xhr',
                response: error.response,
                message: error.message,
                file: error.file,
                line: error.line,
                col: error.col,
            };

            debugHelpers.logError(error, data);
        }
        EventBus.$emit('axios-error', error);
        if (import.meta.env.VITE_PUSHER_ENV === 'local') {
            EventBus.$emit('flash-error', `${error.response.status} - ${error.response.statusText} zie console voor data`);
        } else {
            EventBus.$emit('flash-error', 'Er ging iets fout bij het request, we zijn hiervan op de hoogte gebracht');
        }
        return false;
    }

    return true;
}

/**
 * Handle
 */
export function errorMessagesFromResponse(error) {
    if (error.response && error.config.errorMessageConfig.statusCodes.includes(error.response.status) && error.response.data.props) {
        EventBus.$emit('flash-error', `${error.response.data.props.translations[error.response.data.props.status]}`);
    } else if (error.response && error.response.status === 422) {
        EventBus.$emit('flash-error', 'Formulier bevat ongeldige waarden');
    } else {
        if (error.response.status !== 409) {
            if (import.meta.env.VITE_PUSHER_ENV === 'local') {
                if (error.response && error.response.data && error.response.data.exception) {
                    EventBus.$emit('debug', {
                        'exception': {
                            status: error.response.status,
                            statusText: error.response.statusText,
                            exception: error.response.data.exception,
                            file: error.response.data.file,
                            line: error.response.data.line,
                            message: error.response.data.message,
                            trace: error.response.data.trace,
                        }
                    });
                } else if (error.response && error.response.data && error.response.data.startsWith('<!DOCTYPE html>')) {
                    EventBus.$emit('debug', {'ignition': error.response.data});
                } else if (error.response && error.response.data && error.response.data.startsWith('<script> Sfdump')) {
                    EventBus.$emit('debug', {'dump': error.response.data});
                } else {
                    EventBus.$emit('flash-error', `Response error: ${error.response.status} - ${error.response.statusText}`);
                }
            } else {
                EventBus.$emit('flash-error', 'Er ging iets fout bij het request, we zijn hiervan op de hoogte gebracht');
            }
        }
    }
}