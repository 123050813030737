<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 37.15 30.59">
      <path d="M20.48,17.73a.82.82,0,0,0-.82.82v4.1H6.54V16H29.67v1.44h1.64V16h1.32a2,2,0,0,0,2-2,1.93,1.93,0,0,0-.24-.94l-4-7.22a2.46,2.46,0,0,0-2.16-1.28H8A2.46,2.46,0,0,0,5.84,5.89l-4,7.22a1.93,1.93,0,0,0-.24.94,2,2,0,0,0,2,2H4.9V28.39a2.46,2.46,0,0,0,2.46,2.46H18.84a2.46,2.46,0,0,0,2.46-2.46V18.55A.82.82,0,0,0,20.48,17.73ZM3.58,14.37a.32.32,0,0,1-.32-.32.29.29,0,0,1,0-.16l4-7.22A.83.83,0,0,1,8,6.25H28.21a.82.82,0,0,1,.72.42l4,7.22a.29.29,0,0,1,0,.16.32.32,0,0,1-.32.32Zm16.08,14a.82.82,0,0,1-.82.82H7.36a.82.82,0,0,1-.82-.82v-4.1H19.66Z"
            transform="translate(-1.62 -4.61)"/>
      <path d="M31.41,30.06a2.7,2.7,0,1,0-2.69-2.71A2.69,2.69,0,0,0,31.41,30.06Zm0-4.24a1.55,1.55,0,0,1,1.53,1.53,1.54,1.54,0,1,1-3.07,0A1.55,1.55,0,0,1,31.41,25.82Z"
            transform="translate(-1.62 -4.61)"/>
      <path d="M37.22,28.15a6.6,6.6,0,0,0,.05-.8,6.44,6.44,0,0,0-.05-.75l1.23-1.12a1,1,0,0,0,.19-1.21l-.92-1.6a1,1,0,0,0-1.15-.44l-1.58.5A6.76,6.76,0,0,0,33.64,22l-.36-1.62a1,1,0,0,0-1-.77H30.48a1,1,0,0,0-.95.77L29.17,22a6.18,6.18,0,0,0-1.34.78l-1.59-.5a1,1,0,0,0-1.14.44l-.93,1.6a1,1,0,0,0,.19,1.21l1.23,1.12a6.44,6.44,0,0,0-.05.75,6.6,6.6,0,0,0,.05.8l-1.23,1.13a1,1,0,0,0-.19,1.21l.93,1.6a1,1,0,0,0,1.14.44L27.83,32a5.79,5.79,0,0,0,1.34.78l.36,1.62a1,1,0,0,0,.95.77h1.85a1,1,0,0,0,1-.77l.36-1.62A6.31,6.31,0,0,0,35,32l1.58.51a1,1,0,0,0,1.15-.44l.92-1.6a1,1,0,0,0-.19-1.21ZM27.06,28a3.91,3.91,0,0,1,0-.61,3.48,3.48,0,0,1,0-.55l.06-.46L25.67,25l.83-1.43,1.86.59.37-.28a4.15,4.15,0,0,1,1-.58l.43-.18.42-1.91h1.65l.42,1.91.4.18a3.82,3.82,0,0,1,1,.58l.37.28,1.86-.59L37.14,25,35.7,26.34l.06.46a5.35,5.35,0,0,1,0,.58,5.63,5.63,0,0,1,0,.58l-.06.45,1.44,1.32-.83,1.44-1.86-.6-.37.28a3.11,3.11,0,0,1-1,.56l-.4.2-.42,1.91H30.58l-.42-1.91-.43-.2a3.29,3.29,0,0,1-1-.56l-.37-.28-1.86.6-.83-1.44,1.45-1.32Z"
            transform="translate(-1.62 -4.61)"/>
  </svg>
</template>
<script>
export default {

}
</script>
